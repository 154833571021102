import http from "../http-common";

class AdvanceService {
  getAllMy() {
    return http.get("/advances/my");
  }

  getAllByUserId(userId) {
    return http.get(`/advances?userId=${userId}`);
  }

  create(data) {
    return http.post("/advances", data);
  }

  delete(id) {
    return http.delete(`/advances/${id}`);
  }
}

export default new AdvanceService();