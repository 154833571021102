<template>
  <div>
    <h4>주문 테이블</h4>

    <el-date-picker
      v-model="date"
      type="date"
      placeholder="Pick a day"
      @change="handleDateChange"
    />

    <table>
      <tr>
        <th>메뉴명</th>
        <th>가격</th>
        <th>총 갯수</th>
        <th v-for="order in orders" :key="order.id">
          <el-button type="text" @click="openDialog(order)">{{
            order.user.firstName
          }}</el-button>
        </th>
      </tr>
      <tr v-for="item in menu?.items" :key="item.id">
        <td>
          {{ item.name }}
        </td>
        <td>$ {{ item.price }}</td>
        <td>
          {{ getTotalQuantityByItem(item) }}
        </td>
        <td v-for="order in orders" :key="order.id">
          <el-input
            class="quantity-input"
            v-model="quantities[generateKey(order, item)]"
            :min="0"
            type="number"
            size="small"
            @change="handleQuantityChange(order, item)"
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td><strong>주문 총합</strong></td>
        <td v-for="order in orders" :key="order.id">
          $ {{ getTotalPrice(order) }}
        </td>
        <td>
          <strong>주문 총계 $ {{ getGrandTotalPrice() }}</strong>
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td v-for="order in orders" :key="order.id">
          <el-button
            v-if="!order.paid"
            type="primary"
            size="small"
            round
            @click="openDialog(order)"
            >결제</el-button
          >
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td v-for="order in orders" :key="order.id">
          <el-button
            v-if="!order.paid"
            size="small"
            round
            @click="handleClickDelete(order)"
            >삭제</el-button
          >
        </td>
      </tr>
    </table>

    <el-dialog v-model="showDialog" title="결제" width="50%">
      <h2>이름: {{ selectedUser.firstName }}</h2>
      <h2>선금 잔액: $ {{ selectedUser.advanceBalance }}</h2>

      <el-row justify="center">
        <el-col :span="6">
          <el-input v-model="advance.amount" type="number">
            <template #prefix>$</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="advance.memo" placeholder="메모" />
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="chargeAdvance">선금 충전</el-button>
        </el-col>
      </el-row>

      <h2>선금 충전 내역</h2>
      <el-table :data="selectedUser?.advances">
        <el-table-column label="충전일시">
          <template #default="scope">
            {{ new Date(scope.row.createdAt).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column label="금액">
          <template #default="scope"> $ {{ scope.row.amount }} </template>
        </el-table-column>
        <el-table-column label="메모">
          <template #default="scope">
            {{ scope.row.memo }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button size="small" @click="handleDeleteAdvance(scope.row)">
              삭제
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- <h4>주문 내역</h4>
      <el-table :data="selectedUser.orders.filter(o => !o.archived)">
        <el-table-column label="주문일">  
          <template #default="scope">
            {{ new Date(scope.row.createdAt).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="상태" />
        <el-table-column label="총금액">
          <template #default="scope">
            $ {{ scope.row.totalPrice  }}
          </template>
        </el-table-column>
        <el-table-column label="결제여부">
          <template #default="scope">
            {{ scope.row.paid ? '결제완료' : '결제미완료' }}
          </template>
        </el-table-column>
        <el-table-column prop="paymentType" label="결제방식" />
      </el-table> -->

      <h2>주문 총액: $ {{ order.totalPrice }}</h2>

      <div v-if="!order.paid">
        <el-row justify="center">
          <el-radio v-model="paymentType" label="CASH">현금</el-radio>
          <el-radio v-model="paymentType" label="ADVANCE">선금</el-radio>
        </el-row>
        <el-button type="primary" @click="doPayment()" :loading="loading"
          >결제하기</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
const { DateTime } = require("luxon");
import { ElMessageBox } from "element-plus";
const utils = require("../helper/utils.js");
import OrderService from "../services/OrderService";
import MenuService from "../services/MenuService";
import UserService from "../services/UserService";
import AdvanceService from "../services/AdvanceService";

export default {
  name: "OrderTable",
  data() {
    return {
      date: null,
      loading: false,
      order: null,
      selectedUser: null,
      orders: [],
      menu: null,
      showDialog: false,
      paymentType: "CASH",
      quantities: {},
      advance: {
        amount: 0,
        memo: "",
      },
    };
  },
  methods: {
    getQuantity(order, item) {
      return (
        order.items.filter((i) => i.id === item.id)[0]?.order_item.quantity || 0
      );
    },
    getTotalQuantityByItem(item) {
      return this.orders
        .map((o) => this.getQuantity(o, item))
        .reduce((total, q) => total + q, 0);
    },
    getTotalPrice(order) {
      return Number(order.totalPrice);
    },
    getGrandTotalPrice() {
      return this.orders.reduce((total, o) => this.getTotalPrice(o) + total, 0);
    },
    async retrieveOrdersByDate(dateStr) {
      try {
        const response = await OrderService.getAllByDate(dateStr);
        this.orders = response.data;
        console.log("orders: ", response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async retrieveMenuByDate(dateStr) {
      try {
        const response = await MenuService.findByDate(dateStr);
        console.log(response.data);
        if (response.data.length === 1) {
          this.menu = response.data[0];
        }
      } catch (e) {
        console.log(e);
      }
    },
    async openDialog(order) {
      this.order = order;
      this.selectedUser = (await UserService.get(order.user.id)).data;
      this.advance.amount = 0;
      this.advance.memo = "";
      this.showDialog = true;
    },
    async doPayment() {
      ElMessageBox.confirm("계속 하시겠습니까?", "결제완료 확인", {
        confirmButtonText: "네",
        cancelButtonText: "아니요",
        type: "info",
      })
        .then(async () => {
          this.loading = true;
          try {
            const response = await OrderService.updatePayment(
              this.order.id,
              {
                paid: true,
                paymentType: this.paymentType,
              }
            );
            console.log(response.data);
            this.loading = false;
            this.showDialog = false;
            this.$message.success("성공적으로 결제완료 처리를 했습니다.");

            this.retrieveOrdersByDate(this.date);
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.$message.error(
              "에러가 발생했습니다. 잠시 후 재시도 해주세요."
            );
          }
        })
        .catch(() => {});
    },
    handleDateChange(val) {
      const dateStr = DateTime.fromJSDate(val).toISODate();
      this.retrieveMenuByDate(dateStr);
      this.retrieveOrdersByDate(dateStr);
    },
    handleClickDelete(order) {
      ElMessageBox.confirm(
        `정말 ${order.user.firstName}의 주문을 삭제하시겠습니까?`,
        "Warning",
        {
          confirmButtonText: "네",
          cancelButtonText: "아니요",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            await OrderService.updateArchived(order.id, {
              archived: true,
            });
            this.$message.success("삭제 되었습니다.");
            this.retrieveOrdersByDate(this.date);
          } catch (e) {
            console.log(e);
            this.$message.error(
              "에러가 발생했습니다. 잠시후 다시 시도해주세요."
            );
          }
        })
        .catch(() => {});
    },
    updateQuantities() {
      for (const order of this.orders) {
        for (const item of this.menu.items) {
          this.quantities[this.generateKey(order, item)] = this.getQuantity(
            order,
            item
          );
        }
      }
    },
    generateKey(order, item) {
      return `${order.id}-${item.id}`;
    },
    async retrieveTable() {
      await this.retrieveMenuByDate(this.date);
      await this.retrieveOrdersByDate(this.date);
      this.updateQuantities();
    },
    async handleQuantityChange(order, item) {
      try {
        const response = await OrderService.updateQuantity(order.id, {
          itemId: item.id,
          quantity: this.quantities[this.generateKey(order, item)],
        });
        console.log(response.data);
        this.$message.success("주문 수량이 변경되었습니다.");
        this.retrieveTable();
      } catch (e) {
        console.log(e);
        this.$message.error("에러가 발생했습니다. 잠시 후 재시도 해주세요.");
      }
    },
    async chargeAdvance() {
      ElMessageBox.confirm("계속 하시겠습니까?", "선금 충전", {
        confirmButtonText: "네",
        cancelButtonText: "아니요",
        type: "info",
      })
        .then(async () => {
          try {
            const response = await AdvanceService.create({
              amount: this.advance.amount,
              memo: this.advance.memo,
              userId: this.selectedUser.id,
            });
            console.log(response.data);
            this.$message.success("성공적으로 충전되었습니다.");
            this.advance.amount = 0;
            this.advance.memo = "";

            this.selectedUser = (
              await UserService.get(this.selectedUser.id)
            ).data;
          } catch (e) {
            console.log(e);
            this.$message.error(
              "에러가 발생했습니다. 잠시 후 재시도 해주세요."
            );
          }
        })
        .catch(() => {});
    },
    async handleDeleteAdvance(advance) {
      try {
        await this.$confirm("정말로 선금 충전 내역을 삭제하시겠습니까?");
        await AdvanceService.delete(advance.id);
        this.$message.success("선금 충전 내역이 삭제되었습니다.");
        this.selectedUser = (
          await UserService.get(this.selectedUser.id)
        ).data;
      } catch (e) {
        console.log(e);
        this.message = e?.response?.data;
      }
    },
  },
  async created() {
    try {
      const resp = await MenuService.getClosestFutureMenu()
      const closestFutureMenu = resp.data
      console.log('closestFutureMenu: ', closestFutureMenu)
      this.date = closestFutureMenu.date
    } catch (e) {
      utils.handleHttpError(e, this)
      return
    }

    this.retrieveTable();
  },
};
</script>

<style>
.quantity-input {
  width: 70px;
}
</style>